body {
  background-color: #1A202C;
  font-family: 'Spline Sans Mono', monospace;
  color: white;
}

/*#components-div {*/
/*}*/

.page-component {
  background-color: #1A202C;
  text-align: left;
  padding: 5%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1%;
  width: 60%;
  border-radius: 10px;
  border-style: solid;
  border-width: 2px;
  border-color: #5F9EA0;
}
/*.page-component:hover {*/
/*  box-shadow: 0.5vw 0.5vw #008B8B; !*A9A9A9  FFF8DC*!*/
/*  !*background-color: #1A20F0;*!*/
/*}*/

.page-component img{
  width: 100%;
  height: auto;
}

/* unvisited link */
a:link {
  color: hotpink;
}

/* visited link */
a:visited {
  color: hotpink;
}

/* mouse over link */
a:hover {
  color: Blue;
}

/* selected link */
a:active {
  color: CornflowerBlue;
}

#contact-form-wrapper {
  display: block;
}

#contact-form-wrapper div {
  margin-bottom: 2%;
  margin-right: 1%;
}

/*.contact-form {*/
/*  color: white; !* #1a202c *!*/
/*  background-color: #5F9EA0;*/
/*}*/